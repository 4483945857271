import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ChartRenderer from '../components/ChartRenderer';
import Dashboard from '../components/Dashboard';
import DashboardItem from '../components/DashboardItem';
import KPIChart from '../components/KPIChart';
const cards = [
  {
    title: 'Totaal aantal bestellingen',
    query: {
      measures: ['SalesOrder.count'],
    },
    difference: 'SalesOrder',
    duration: 1.25,
  },
  {
    title: 'Totaal aantal unieke klanten',
    query: { measures: ['SalesOrder.customerEmail'] },
    difference: 'SalesOrder',
    duration: 1.5,
  },
  {
    title: 'Totaal omzet',
    query: {
      measures: ['SalesOrder.totalPaid'],
    },
    difference: 'SalesOrder',
    duration: 2.25,
  },
];
const DashboardItems = [
  {
    id: 1,
    vizState: {
      query: {
        measures: ['SalesOrderItem.count'],
        timeDimensions: [],
        order: {
          'SalesOrderItem.count': 'desc',
        },
        dimensions: ['SalesOrderItem.sku', 'SalesOrderItem.name'],
        filters: [
          {
            member: 'SalesOrder.status',
            operator: 'contains',
            values: ['complete', 'processing'],
          },
        ],
        limit: 5,
      },
      chartType: 'table',
    },
  },
  {
    id: 2,
    vizState: {
      query: {
        measures: ['SalesOrder.totalPaid'],
        timeDimensions: [
          {
            dimension: 'SalesOrder.updatedAt',
            granularity: 'month',
          },
        ],
        order: [['SalesOrder.updatedAt', 'desc']],
        dimensions: [],
        filters: [
          {
            member: 'SalesOrder.status',
            operator: 'contains',
            values: ['complete', 'processing'],
          },
        ],
        limit: 1000,
      },
      chartType: 'line',
    },
  },
];

const DashboardPage = () => {
  const dashboardItem = (item) => (
    <Grid container spacing={4}>
      {cards.map((item, index) => {
        return (
          <Grid key={item.title + index} item lg={4} sm={6} xl={4} xs={12}>
          
          </Grid>
        );
      })}
      <Grid item lg={12} md={12} xl={12} xs={12}>
        <DashboardItem title={item.name}>
          <ChartRenderer vizState={item.vizState} />
        </DashboardItem>
      </Grid>
    </Grid>
  );

  const Empty = () => (
    <div
      style={{
        textAlign: 'center',
        padding: 12,
      }}
    >
      <Typography variant="h5" color="inherit">
        There are no charts on this dashboard. Use Playground Build to add one.
      </Typography>
    </div>
  );

  return DashboardItems.length ? (
    <Dashboard>
      {cards.map((item, index) => {
        return (
          <Grid key={item.title + index} item lg={4} sm={6} xl={4} xs={12}>
            <KPIChart {...item} />
          </Grid>
        );
      })}
      {DashboardItems.map(dashboardItem)}
    </Dashboard>
  ) : (
    <Empty />
  );
};

export default DashboardPage;
