import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import DashboardPage from './pages/DashboardPage';
import DataTablePage from './pages/DataTablePage';
import { Auth0Provider } from "@auth0/auth0-react";
import App from './App';

ReactDOM.render(
  <Auth0Provider
    audience={process.env.REACT_APP_AUTH0_AUDIENCE}
    domain={process.env.REACT_APP_AUTH0_DOMAIN}
    clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
    scope={'openid profile email'}
    redirectUri={process.env.REACT_APP_AUTH0_REDIRECT_URI || window.location.origin}
    onRedirectCallback={() => {}}
  >
  <Router>
      <App>
        <Route key="index" exact path="/" component={DashboardPage} />
          <Switch>
           <Redirect exact from="/" to="/dashboard"/>
            <Route key="index" exact path="/dashboard" component={DashboardPage} />
            <Route key="table" path="/orders" component={DataTablePage} />
            <Redirect to="/dashboard" />
          </Switch>
      </App>
  </Router>
  </Auth0Provider>,
document.getElementById('root'));