import React from "react";
import { makeStyles } from "@material-ui/styles";

import Table from "../components/Table.js";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  content: {
    marginTop: 15
  },
}));

const DataTablePage = () => {
  const classes = useStyles();

  const query = {
    "timeDimensions": [
      {
        "dimension": "SalesOrder.createdAt",
        "granularity": "day"
      }
    ],
    "dimensions": [
      "SalesOrder.incrementId",
      "SalesOrder.status",
      "SalesOrder.createdAt",
      "SalesOrder.customerFirstname",
      "SalesOrder.customerLastname"
    ],
    order: {
      'SalesOrder.createdAt': 'desc'
    },
  };

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Table query={query}/>
      </div>
    </div>
  );
};

export default DataTablePage;